import React, { useEffect, useRef, useState } from 'react';
import MagicCardUnit from './MagicCardUnit';
import img_projet1 from '../../webp/vocabularium_thumb.webp';
import img_projet2 from '../../webp/biographe_presentation.webp';
import img_projet3 from '../../webp/zoohoho_thumb.webp';
import img_projet4 from '../../webp/unit_test_thumb.webp';
import img_projet5 from '../../webp/imhotep_thumb.webp';
import img_projet6 from '../../webp/catchadata_thumb.webp';
import img_projet7 from '../../webp/psycho.webp';
import img_projet8 from '../../webp/fedorashop_thumb.png'
import {SiPython, SiReact, SiJavascript, SiAngularjs} from 'react-icons/si';
import {FaLaravel} from 'react-icons/fa';
import Lottie from 'lottie-react';
import animationData from '../../../src/Animation-coding3.json';
const Portfolio = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Déconnecter après que l'animation soit déclenchée
        }
      },
      { threshold: 0.1 } // Seuil de visibilité (10% visible déclenche l'animation)
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (observer && containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const projets = [
    {
      id:1,
      nomProjet:'FedoraShop',
      online:true,
      onlineLink:'https://demo-fedorashop.com',
      evaluation:false,
      imageFront:img_projet8,
      techno:<SiAngularjs/>,
      textePrez:'Projet fullstack de e-commerce from scratch. Développé avec Angular 16 /Spring Boot et une base de données MySQL. Vous pouvez tester ses fonctionnalités en ligne.',
      colorBorder:'belunga8',
      colorBorderBack:'color_fedora',
      colorText: 'color_text_fedora',
      detailTech:'Angular, Spring Boot, MySQL, Stripe, Optimitic Lock, websocket'

    },
    {
      id:2,
      nomProjet:'Psycho',
      online:true,
      onlineLink:'https://biaiscognitif.fr',
      evaluation:false,
      imageFront:img_projet7,
      techno:<SiAngularjs/>,
      textePrez:
      `
      Projet fullstack développé avec Angular 16 en front-end et NodeJS/Express
      en back-end. L'API est déployée sur un serveur web Nginx, dans un VPS.
      `,
      colorBorder:'belunga7',
      colorBorderBack:'color_psycho',
      colorText: 'color_text_psycho',
      detailTech:'Angular, NodeJS, Express, couchDB, NoSQL, ThreeJS',

    },
    {
      id:3,
      nomProjet:'Biographe',
      online:true,
      onlineLink:'https://pcolletbiographe.me',
      evaluation:false,
      imageFront:img_projet2,
      techno:<SiReact/>,
      textePrez:'Projet fullstack: appli React avec control panel pour l\'activité d\'un biographe Niçois.',
      colorBorder:'belunga5',
      colorBorderBack:'color_biog',
      colorText: 'color_text_biog',
      detailTech:'React, NodeJS, NoSQL, GoogleMap API',

    },

    {
      id:4,
      nomProjet:'Zoohoho',
      online:false,
      onlineLink:'',
      evaluation:false,
      imageFront:img_projet3,
      techno:<FaLaravel/>,
      textePrez:'Projet fullstack réalisé avec le framework PHP Laravel 8',
      colorBorder:'belunga2',
      colorBorderBack:'color_zoo',
      colorText: 'color_text_zoo',
      detailTech:'Laravel 8, MySQL, Bootstrap',

    },
    {
      id:5,
      nomProjet:'Vocabularium',
      otherfile:false,
      online:false,
      onlineLink:'',
      evaluation:false,
      imageFront:img_projet1,
      techno:<SiPython/>,
      textePrez:'Application de bureau python permettant de conserver et mémoriser le vocabulaire rencontré au fil de mes lectures dans un contexte ludique.',
      colorBorder:'belunga1',
      colorBorderBack:'color_vocab',
      colorText: 'color_text_vocab',
      detailTech:'Tkinter, Pandas, SQLite',

    },
    {
      id:6,
      nomProjet:'Imhotep',
      online:false,
      onlineLink:'',
      evaluation:false,
      imageFront:img_projet5,
      techno:<SiPython/>,
      textePrez:'Application métier réalisée pour l\'école d\'ingénieurs de Tarbes permettant de créer des reportings automatiques.',
      colorBorder:'belunga4',
      colorBorderBack:'color_imhotep',
      colorText: 'color_text_imhotep',
      detailTech:'Tkinter, JSON, CouchDB, win32, Pandas',

    },
    {
      id:7,
      nomProjet:'CatchaData',
      online:false,
      onlineLink:'',
      evaluation:false,
      imageFront:img_projet6,
      techno:<SiPython/>,
      textePrez:'Application métier réalisée pour l\'école d\'ingénieurs de Tarbes permettant de suivre des indicateurs et d\'extraire des données via l\'API du SI.',
      colorBorder:'belunga6',
      colorBorderBack:'color_catcha',
      colorText: 'color_text_catcha',
      detailTech:'Pandas, JSON, SQLite, CouchDB',
   
    },

    {
      id:8,
      nomProjet:'Unit Test',
      online:false,
      onlineLink:'',
      evaluation:true,
      imageFront:img_projet4,
      techno:<SiJavascript/>,
      textePrez:'Tests unitaires sur l\'application Cordova pour la Tvet Academy',
      colorBorder:'belunga3',
      colorBorderBack:'color_unit',
      colorText: 'color_text_unit',
      detailTech:'Mocha',

    },




  ]





  return (
    <div className="sectionDesRealisations">

      <div className='

          h-[80px] 
          opacity-90'
          />
   
      

        {/* <MagicCard/> */}
        <div 
        className='mx-auto'>
              <div className='lottieContainer'>
      <Lottie animationData={animationData} loop={true} className='lottieFile' />
    </div>
          {/* <h1 className='titreRealisations'>Quelques réalisations</h1> */}

          {/* <h1 className='font-poppins text-blue-800 py-5 text-center text-4xl'>Réalisations</h1> */}
          <section
          ref={containerRef}
          className={`generalcont ${isVisible ? 'show' : ''}`}
        >

          {
            projets.map( x => {
              return(

                <div className="magicCard" key={x.id}>

                  <MagicCardUnit
                  className=''
                  id_projet={x.id}
                  nom_projet={x.nomProjet}
                  image_projet={x.imageFront}
                  techno_projet={x.techno}
                  texte_projet={x.textePrez}
                  border_projet={x.colorBorder}
                  border_back_projet={x.colorBorderBack}
                  detail_tech_projet={x.detailTech}
                  backimage_projet={x.backImage}
                  colorText_projet={x.colorText}
                  isOnline={x.online}
                  isEvaluation={x.evaluation}
                  isOtherFile={x.otherfile}
                  onlineLink={x.onlineLink}
                  />
                </div>

              )

            })
          }
          
        </section>
        </div>

    </div>
  )
}

export default Portfolio