import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Menu from '../Menu/Menu';
import '../../../src/articlecontent.css';
import BackToTop from '../BackToTop';
import ArticleNotAvailable from '../ArticleNotAvailable/ArticleNotAvailable';
import Footer from '../Footer/Footer';
import NotFound from '../NotFound/NotFound';

const ArticleBook = () => {
    const [bookcontent, setBookContent] = useState(null);
    const { id } = useParams();
    const containerRef = useRef(null);

    useEffect(() => {
        fetch(`https://stickystraydog.com/ronify/books/${id}`)
            .then(response => response.json())
            .then(data => {
                console.log('Books fetched:', data);
                setBookContent(data);
            })
            .catch(error => console.error('Error fetching books:', error));
    }, [id]);

    if (parseInt(id) > 6 || parseInt(id) < 1 || !/^\d+$/.test(id)) {
        return <NotFound />;
    }

    return (
        <section className='test'>


            <Menu />
            <section className="pt-[95px] background-['#03031A']"/>

            {bookcontent !== null && bookcontent.isAvailable ? (
                <p ref={containerRef} dangerouslySetInnerHTML={{ __html: bookcontent.book_article_title }}></p>
            ) : (
                bookcontent !== null && <ArticleNotAvailable />
            )}
            
            <BackToTop/>
            <Footer/>
        </section>
    );
}

export default ArticleBook;
