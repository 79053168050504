import React, { useState, useRef, useEffect } from 'react';
import cursorImage from './cursor7.png'; 
import FalshBtn2 from '../FalshBtn2';

const DrawingCanvas = () => {
  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [currentColor, setCurrentColor] = useState('#000000');
  const [canvasHeight, setCanvasHeight] = useState(555);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);
  const MIN_COLORED_PIXELS = 4000; 

  useEffect(() => {
    const interval = setInterval(() => {
      let randomColor;
      do {
        randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
      } while (randomColor === '#ffffff');
      setCurrentColor(randomColor);
    }, 200);
  
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.lineWidth = 8;
    context.lineCap = 'round';

    const handleMouseDown = (event) => {
      setDrawing(true);
      drawPoint(event);
    };

    const handleMouseMove = (event) => {
      if (!drawing) return;
      drawPoint(event); 
    };

    const handleMouseUp = () => {
      setDrawing(false);
    };

    const drawPoint = (event) => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      context.fillStyle = currentColor;
      context.beginPath();
      context.arc(x, y, 8, 0, Math.PI *2);
      context.fill();
    };

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);
    };
  }, [drawing, currentColor]);

  const handleSendDrawing = async () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL(); 

    if (isEmptyDrawing(canvas)) {
      setPopupMessage('Le canvas est trop peu rempli pour être envoyé !');
      setPopupVisible(true);
      setTimeout(() => {
        setPopupVisible(false);
      }, 2000);
      return;
    }

    const formData = new FormData();
    formData.append('drawing', dataURL);

    try {
      const response = await fetch('https://stickystraydog.com/ronify/drawings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Indiquer que le corps de la requête est au format JSON
        },
        body: JSON.stringify({ drawing: dataURL })
      });

      if (!response.ok) {
        throw new Error('Error');
      }
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      setPopupMessage('dessin envoyé !');
    } catch (error) {
      console.error("Error", error);
      setPopupMessage("Le dessin n'a pas été envoyé.");
    } finally {
      setPopupVisible(true);
      setTimeout(() => {
        setPopupVisible(false);
      }, 2000);
    }
  };

  const isEmptyDrawing = (canvas) => {
    const context = canvas.getContext('2d');
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    let coloredPixelCount = 0;
  
    for (let i = 0; i < imageData.data.length; i += 4) {
      // Vérifier si les composantes RGB ne sont pas toutes égales à 0 (pixel coloré)
      if (imageData.data[i] !== 0 || imageData.data[i + 1] !== 0 || imageData.data[i + 2] !== 0) {
        coloredPixelCount++;
      }
  
      if (coloredPixelCount >= MIN_COLORED_PIXELS) {
        // S'il y a suffisamment de pixels colorés, le dessin n'est pas vide
        return false;
      }
    }
  
    // Si le nombre de pixels colorés est inférieur au minimum requis, le dessin est considéré comme vide
    return true;
  };

  useEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;
      const parentWidth = canvas.parentElement.clientWidth; // Largeur du conteneur parent
      canvas.width = parentWidth; // Définir la largeur du canvas
    };

    window.addEventListener('resize', handleResize); // Écouter les événements de redimensionnement de la fenêtre
    handleResize(); // Appeler la fonction de gestion de redimensionnement une fois pour définir la largeur initiale

    return () => {
      window.removeEventListener('resize', handleResize); // Nettoyer l'écouteur d'événement lors du démontage du composant
    };
  }, []);

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    const x = touch.clientX - canvasRef.current.offsetLeft;
    const y = touch.clientY - canvasRef.current.offsetTop;
    drawPoint(x, y);
    setDrawing(true);
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    if (!drawing) return;
    const touch = event.touches[0];
    const x = touch.clientX - canvasRef.current.offsetLeft;
    const y = touch.clientY - canvasRef.current.offsetTop;
    drawPoint(x, y);
  };

  const handleTouchEnd = () => {
    setDrawing(false);
  };

  const drawPoint = (x, y) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.fillStyle = currentColor;
    context.beginPath();
    context.arc(x, y, 8, 0, Math.PI * 2);
    context.fill();
  };

  return (
    <>
        <div className='container_canvas_bouton'>
          <div className='zoneArtistique'>
            <div className="canvas-container"> {/* Ajout de la classe canvas-container */}
              <canvas
                ref={canvasRef}
                height={canvasHeight} 
                className='dessiner'
                style={{ cursor: `url(${cursorImage}) 10 10, auto`, touchAction: 'none'  }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              ></canvas>
            </div>
          </div>
        </div>

      <div className="flex justify-center py-8">
        <FalshBtn2 color={"cyan"} texte={"envoyer"} handleClick={handleSendDrawing} />
      </div>
      {popupVisible && (
  <div className={`popup ${popupMessage === 'dessin envoyé !' ? 'success' : 'error'}`}>
    <p>{popupMessage}</p>
  </div>
)}
    </>
  );
};

export default DrawingCanvas;



